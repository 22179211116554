.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .button,
.area--one .unitThree .button,
.area--one .unitFour--statusSold .button {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .button,
.area--one .unitFour--statusRented .button {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .button {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .button {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
.area--one {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--one .unit {
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}
.area--one .unitOne--1-2 {
  width: calc(50% - 30px);
}
.area--one .unitOne--2-3 {
  width: calc(50% - 30px);
}
.area--one .unitOne--1-3 {
  width: calc(50% - 30px);
}
.area--one .unitTwo--1-2 {
  width: calc(50% - 30px);
}
.area--one .unitThree {
  width: calc(1920px - (var(--deskSpace) * 2)) !important;
  max-width: var(--deskMaxWidth);
  flex-shrink: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.area--one .unitThree .unit__background {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 50%;
  height: 100%;
}
.area--one .unitThree .unit__background * {
  height: 100%;
}
.area--one .unitThree .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitThree .unit__content {
  padding-left: 50%;
}
.area--one .unitFour {
  width: calc(50% - 30px);
}
/*# sourceMappingURL=./screen-medium.css.map */